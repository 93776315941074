import React, { Component } from 'react';
import './Main.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import More from './More.js';
import Home from './Home.js';

class Main extends Component {
    render() {
        return (
            <div>
                <Router>
                    
                    <Routes>
                        <Route path="/" element={<Home/>}>
                        </Route>
                        <Route path="/more" element={<More/>}>
                        </Route>
                    </Routes>
                </Router>
            </div>
        )
    }
}


export default Main;