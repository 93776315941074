import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";

class More extends Component {
    render() {
        return(
            <div className = "main">
            <div className = "middle">
                <h1>more - james gavin</h1>
                <hr style={{
                        color: 'grey',
                        backgroundColor: 'grey',
                        height: .5,
                        borderColor: 'grey'
                    }} />
                <p>Nothing to see here...</p>
                <Link to ="/" style={{ textDecoration: 'none' }}>&larr;</Link>
            </div>
        </div>
        );
    }
}

export default More;
