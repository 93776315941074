import React, { Component } from 'react';
import './Main.css';
import {
    Link
} from "react-router-dom";
import More from './More.js';


class Home extends Component {
    render() {
        return (
            <div className="main">
                <div className="middle">
                    <h1>james gavin</h1>
                    <hr style={{
                        color: 'grey',
                        backgroundColor: 'grey',
                        height: .5,
                        borderColor: 'grey'
                    }} />
                    <p><a href="mailto: james@jmsgvn.com" target="_blank" rel="noopener">Email me</a></p>
                    <Link to="/more" style={{ textDecoration: 'none' }}>&rarr;</Link>

                </div>
            </div>
        );
    }
}

export default Home;
